<template>
  <VInput
    label="Артикул"
    ref="articleInput"
    name="article"
    :custom-validation="checkIsValidArticle"
    :disabled="isDisabled"
    :loading="isLoading"
    required
    @changeValue="validationArticle"
  >
    <Validation for="required"/>
    <Validation for="customValidation">{{ errorMessage }}</Validation>
  </VInput>
</template>

<script>
import Validation from '@/components/Form/Validation'
import VInput from '@/components/Form/Vinput/VInput'

import { mixinArticles } from '@/mixins/views/mixinArticles'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mixinTimeout } from '@/mixins/mixinTimeout'

import { ARTICLE_ERROR_MESSAGE } from '@/const/validation'
import { showAlertError } from '@/utils/store/alert'

export default {
  components: { VInput, Validation },
  mixins: [mixinIsLoading, mixinTimeout, mixinArticles],
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isValid: true,
    errorKey: ''
  }),
  computed: {
    errorMessage () {
      return ARTICLE_ERROR_MESSAGE[this.errorKey]
    },
    productId () {
      return +this.$route.params.productId
    },
    templatePositionId () {
      if (!this.$route.name.includes('TemplatePosition')) {
        return null
      }

      return +this.$route.params.id
    }
  },

  methods: {
    checkIsValidArticle () {
      return this.isValid
    },

    async validationArticle (value) {
      this.clearTimeoutId()
      this.setIsValid()
      if (!value) return
      this.setTimeoutId(() => this.check(value), 700)
    },

    async check (article) {
      try {
        this.startLoading()

        if (!this.checkInProductsBy(article)) {
          return this.setInvalidBecause('hasProduct')
        }

        if (!this.checkInTemplatePositionBy(article)) {
          return this.setInvalidBecause('hasTemplate')
        }
      } catch (e) {
        showAlertError(e)
        this.setIsValid(false)
      } finally {
        this.finishLoading()
        this.$refs.articleInput.touch()
      }
    },

    setInvalidBecause (key) {
      this.errorKey = key
      this.setIsValid(false)
    },

    checkInProductsBy (article) {
      const product = this.findProductBy(article)
      if (!product) return true

      if (this.productId) {
        return product.id === this.productId
      }

      return false
    },

    checkInTemplatePositionBy (article) {
      const template = this.findTemplatePositionBy(article)
      if (!template) return true

      if (this.templatePositionId) {
        return template.id === this.templatePositionId
      }

      return false
    },

    setIsValid (bool = true) {
      this.isValid = bool
    }
  }
}
</script>
